.header {
	padding: 19px 15px;
}

.logo {
	display: block;
	width: 108px;
	margin: 0 auto;
	img {
		@include width;
		display: block;
	}
}

.container {
	.logo {
		width: 130px;
	}
}