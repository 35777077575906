.wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
}

.container {
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 14px 10px;
}

.btn {
	@include animate(background);
	display: block;
	color: $btn-color;
	background: $btn-background;
	padding: $btn-padding;
	font-size: $btn-font-size;
	text-align: center;
	text-decoration: none;
	&:hover {
		background: darken($btn-background, 15%);
	}
	&.style {
		border-radius: 20px;
		@include media('<mobile') {
			font-size: 10px;
			line-height: 1.6;
		}
	}
}

.video-banner {
	position: relative;
	overflow: hidden;
	img {
		@include width;
		display: block;
	}
}

.contact-form {
	position: relative;
	padding: 22px 15px;
	@include media('>=mobile') {
		padding: 22px 28px 22px 35px;
	}
}

.form-row {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 0 0 21px;
	.column {
		width: 48%;
		padding: 7px 0 0;
		.form-row {
			padding: 0;
			position: relative;
		}
		.form-group {
			&:first-child {
				.input-control {
					background: url(/src/templates/godtlevert/images/check.svg) no-repeat 0 3px;
					background-size: 10px 7px;
				}
			}
		}
		.input-control {
			padding: 2px 12px 3px !important;
		}
	}
}

.form-group {
	width: 48%;
	&.w-100 {
		width: 100%;
	}
	label {
		display: block;
		color: $black;
		margin: 0 0 5px;
		font: #{12px}/#{1} $work-sans;
		@include media('>=mobile') {
			font-size: 13px;
		}
	}
}

.input-control {
	display: block;
	width: 100%;
	font-size: 11px;
	line-height: 1;
	outline: none;
	padding: 2px 0 3px !important;
	border-width: 0 0 1px !important;
}

.vipps-logo {
	display: block;
	margin: -21px auto 12px;
	width: 60px;
	&.style {
		margin: -4px auto 7px;
		@include media('>=mobile') {
			margin: -4px auto 11px;
		}
	}
}

.checkbox-row {
	padding: 8px 0;
	label {
		font-size: 10px;
		color: $black1;
		@include media('>=mobile') {
			font-size: 11px;
		}
	}
}

.form-block {
	background: $off-white;
	padding: 20px 15px 14px;
	@include media('>=mobile') {
		padding: 20px 28px 14px 21px;
	}
}

.row-form {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: 0 0 11px;
	.box {
		width: 48%;
		&.w-100 {
			width: 100%;
		}
	}
	.column {
		width: 48%;
		.row-form {
			padding: 0;
		}
	}
	label {
		color: $black1;
		display: block;
		margin: 0 0 8px;
		font:#{11px}/#{1} $brownStd;
		@include media('>=tablet') {
			font-size: 13px;
		}
	}
	.row {
		padding: 7px 0 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
}

.form-control {
	border-color: $gray2 !important;
	width: 100%;
	outline: none;
	font:#{11px}/#{1} $brownStd;
	padding: 11px 11px 6px 11px !important;
	&.disable {
		background: $off-white;
		border-color: #d1cecb !important;
	}
	&.w-30 {
		width: 29%;
	}
	&.w-70 {
		width: 68%;
	}
}

.checkbox-area {
	padding: 4px 0;
	.jcf-checkbox {
		width: 16px;
		height: 16px;
		span {
			background: url(/src/templates/godtlevert/images/check2.svg) no-repeat 50% 50%;
			background-size: 12px 8px;
		}
	}
	label {
		color: $black1;
		text-decoration: underline;
		font:#{10px}/#{1} $brownStd;
		@include media('>=mobile') {
			font-size: 11px;
		}
	}
}

.flex-bottom {
	display: flex;
	align-items: flex-end;
	padding: 0 !important;
}
