/* custom checkbox styles */

.jcf-checkbox {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: default;
    background: #fff;
    border: 1px solid $green;
    margin: 0 3px 0 0;
    height: 25px;
    width: 25px;
}

.jcf-checkbox span {
    position: absolute;
    display: none;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: url(/src/templates/godtlevert/images/check.svg) no-repeat 50% 50%;
    background-size: 10px 7px;
}

.jcf-checkbox input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    left: 0;
    top: 0;
}

.jcf-checkbox.jcf-checked span {
    display: block;
}

/* common custom form elements styles */

.jcf-disabled {
    background: #ddd !important;
}
