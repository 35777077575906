// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$gray: #808283;
$black: #000006;
$black1: #231f20;
$white: #fff;
$light-blue: #39f;
$orange: #f15d24;
$green: #307d4f;
$off-white: #f6f1ec;
$gray2: #d2cfcd;

$work-sans: 'Work Sans', sans-serif;
$brownStd: 'BrownStd';
$base-font-sans-serif: $work-sans, 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $black;
$base-background-color: $white;
$font-size-base: 13px;
$line-height-base: 1.2;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;

// Liks
$base-link-color: $light-blue;

// Buttons 
$btn-color: $white;
$btn-background: $orange;
$btn-padding: 7px 15px;
$btn-font-size: 12px;

// Forms
$form-element-padding: $btn-padding;
$form-element-border-color: $green;
$form-element-focus-border-color: $green;
$placeholder-color: $gray;

// Headers
$h1-font-size: 30px !default;
$h2-font-size: 27px !default;
$h3-font-size: 24px !default;
$h4-font-size: 21px !default;
$h5-font-size: 17px !default;
$h6-font-size: 15px !default;

$headings-font-family: inherit !default;
$headings-color: inherit !default;


$animation-speed: 0.3s;
